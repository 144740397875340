export default async function sfApiNextRequest({path, body}) {
  var domain = {
    development: 'http://localhost:3000',
    // staging: 'https://sf-api-next-staging.vercel.app', // does not work
    production: 'https://sf-api-next.vercel.app'
  }[process.env.NODE_ENV];

  if (window.location.href.includes('staging')) domain = 'https://sf-api-next-staging.vercel.app';

  var response = await (await fetch(`${domain}${path}`, {
    method: 'post',
    mode: 'cors',
    body: JSON.stringify(body),
    headers: {'Content-Type': 'application/json'}
  })).json();

  return response;
}