import { PureComponent, createRef } from 'react';
import { Text, Link, Label, DocumentTitle, LabelledView, PickerInput, Tooltip, Button, Loading } from '@symbolic/rn-lib';
import { View, ScrollView, TouchableOpacity, Image, ActivityIndicator } from 'react-native';
import { connect, setAppData } from '@symbolic/redux';
import { resourceActions } from '~/redux';
import { products } from '@symbolic/lib';
import moment from 'moment';
import _ from 'lodash';
import K from '~/k';

import { formatPrice } from '~/helpers/price-helper';
import { getNameFor } from '~/helpers/product-order-helper';
import { downloadCsv } from '~/helpers/csv-helper';
import { getUserEmployeeOrGuest } from '~/helpers/get-user-employee-or-guest';

import SecondaryHeader from '~/components/secondary-header';

import moreIcon from '~/assets/more-icon-black.png';
import downloadIcon from '~/assets/download-icon-black.png';
import leftArrowIcon from '~/assets/left-arrow.png';
import sfApiNextRequest from '~/helpers/sf-api-next-request';

var {getLastCompletedProductOrderStatus, getFilteredProductOrderStatuses} = products;

class ProductOrderRow extends PureComponent {
  state = {
    optionsDropdownIsVisible: false,
  };

  toggleArchive = async ({productOrder}) => {
    this.props.updateProductOrder({id: productOrder.id, props: {archived: (productOrder.archived === 1 ? 0 : 1)}});
  };

  render() {
    var {productOrder, productOrderStatuses, productOrderStatusesByKey, activeOrgId, detailLevelFilterValue, isGuest, isGuestMode} = this.props;
    var {optionsDropdownIsVisible} = this.state;

    var lastCompletedProductOrderStatus = _.get(getLastCompletedProductOrderStatus({productOrderStatuses, statusData: productOrder.statusData}), 'key'); //TODO use productOrder.lastCompletedStatusTitle from sf-api-next instead?

    var cellStyle = {marginRight: K.spacing, opacity: 0.5};
    var overflowCellStyle = {whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis'};

    return (
      <View
        style={{flexDirection: 'row', borderRadius: K.borderRadius, backgroundColor: K.colors.gray, marginBottom: K.margin, marginHorizontal: K.spacing}}
        dataSet={{'hover-background-double-gray': 1}}
      >
        <Link
          to={`/orders/${productOrder.orgSpecificId}`}
          style={{flex: 1, paddingVertical: K.spacing / 2, paddingLeft: K.spacing}}
          nativeID={`OrdersPageProductOrderRow-${productOrder.id}`}
        >
          <View
            style={{flexDirection: 'row', alignItems: 'flex-start', opacity: productOrder.archived ? 0.3 : 1}}
          >
            <Text style={{...cellStyle, minWidth: 300, flex: 1, opacity: 1}}>{productOrder.title}</Text>
            {activeOrgId !== 1798 && (
              <>
                <Text style={{...cellStyle, width: 75}}>#{productOrder.orgSpecificId}</Text>
                <Text style={{...cellStyle, width: 150}}>{productOrder.productTitle}</Text>
                <Text style={{...cellStyle, width: 150}}>{productOrder.productCategoryTitle}</Text>
              </>
            )}
            <Text style={{...cellStyle, width: 150}}>{`${productOrder.ownerFirstName} ${productOrder.ownerLastName}`}</Text>
            {(activeOrgId === 850 && (detailLevelFilterValue === 'advanced')) && (<>
              <Text style={{...cellStyle, width: 150}}>{productOrder.firmName}</Text>
            </>)}
            {activeOrgId === 1798 && (<>
              <Text style={{...cellStyle, width: 150}}>{productOrder.contactName}</Text>
              <Text style={{...cellStyle, width: 100}}>#{productOrder.purchaseOrderNumber}</Text>
              <a style={{...cellStyle, width: 150, wordBreak: 'break-all', textDecoration: 'none'}} target="_blank" href={productOrder.externalLink1} rel="noreferrer">
                <Text>{productOrder.externalLink1}</Text>
              </a>
            </>)}
            <Text style ={{
              ...cellStyle, width: 150,
              ...((lastCompletedProductOrderStatus !== productOrder.lastCompletedStatusKey) && {color: 'red'})
            }}>
              {_.get(productOrderStatusesByKey, `${lastCompletedProductOrderStatus}.title`)}
            </Text>
            {(activeOrgId === 850 && (detailLevelFilterValue === 'advanced')) && (<>
              <Text style ={{...cellStyle, width: 100, textAlign: 'right'}}>{moment(productOrder.created).format('M/D/YYYY')}</Text>
              <Text style ={{...cellStyle, width: 100, textAlign: 'right'}}>{moment(productOrder.lastUpdated).format('M/D/YYYY')}</Text>
              <Text style ={{...cellStyle, width: 100, textAlign: 'right'}}>${formatPrice(_.get(productOrder, 'costs.orderSubTotal', 0))}</Text>
              <Text style ={{...cellStyle, width: 100, textAlign: 'right'}}>${formatPrice(_.get(productOrder, 'costs.discount', 0))}</Text>
              <Text style ={{...cellStyle, width: 100, textAlign: 'right'}}>{_.get(productOrder, 'costs.shippingIsTbd') === true ? 'TBD' : `$${formatPrice(_.get(productOrder, 'costs.shipping', 0))}`}</Text>
              <Text style ={{...cellStyle, width: 100, textAlign: 'right'}}>${formatPrice(_.get(productOrder, 'costs.tax', 0))}</Text>
            </>)}
            <Text style ={{...cellStyle, width: 125, textAlign: 'right'}}>${formatPrice(_.get(productOrder, 'costs.orderTotal', 0))}</Text>
            <Text style ={{...cellStyle, width: 125, textAlign: 'right'}}>${formatPrice(productOrder.amountPaidInCents)}</Text>
            {(activeOrgId === 850 && (detailLevelFilterValue === 'advanced' && (!isGuest || !isGuestMode))) && (<>
              <Text style={{...cellStyle, width: 100, textAlign: 'right'}}>{productOrder.lastPaymentDate}</Text>
              <Text style={{...cellStyle, width: 50, textAlign: 'center'}}>{productOrder.productInstancesCount}</Text>
              <Text style={{...cellStyle, width: 100}}>{productOrder.screwType}</Text>
              <Text
                style={{...cellStyle, width: 150, ...overflowCellStyle}}
                dataSet={{'hover-white-space-normal': 1}}
              >
                {productOrder.engravingTypes}
              </Text>
              <Text
                style={{...cellStyle, width: 250, ...overflowCellStyle}}
                dataSet={{'hover-white-space-normal': 1}}
              >
                {productOrder.plateFinishes}
              </Text>
              <Text
                style={{...cellStyle, width: 200, ...overflowCellStyle}}
                dataSet={{'hover-white-space-normal': 1}}
              >
                {productOrder.mechanismFinishes}
              </Text>
              <Text style={{...cellStyle, width: 125, textAlign: 'right'}}>{productOrder.estimatedShippingDate ? moment(productOrder.estimatedShippingDate).utc().format('M/D/YYYY') : ''}</Text>
              <Text style={{...cellStyle, width: 125, textAlign: 'right'}}>{_.get(productOrder, 'commandData.number', '')}</Text>
              <Text style={{...cellStyle, width: 125, textAlign: 'right'}}>
                {_.get(productOrder, 'commandData.date') ? moment(_.get(productOrder, 'commandData.date')).format('M/D/YYYY') : ''}
              </Text>
              <Text style={{...cellStyle, width: 125, textAlign: 'right'}}>
                {_.get(productOrder, 'commandData.totalInCents') ? `€${formatPrice(_.get(productOrder, 'commandData.totalInCents'))}` : ''}
              </Text>
            </>)}
          </View>
        </Link>
        <View style={{flexDirection: 'row', justifyContent: 'flex-end', paddingVertical: K.spacing / 2, width: 25}}>
          <TouchableOpacity
            style={{marginHorizontal: K.spacing, opacity: optionsDropdownIsVisible ? 1 : 0.3}}
            onPress={() => this.setState({optionsDropdownIsVisible: !optionsDropdownIsVisible})}
          >
            <Image source={moreIcon} style={{...K.defaultIconSize}}/>
          </TouchableOpacity>
        </View>
        {optionsDropdownIsVisible && (
          <View style={{position: 'absolute', right: 0, top: K.spacing + K.defaultIconSize.height, padding: K.spacing, backgroundColor: '#000', zIndex: 1, borderRadius: K.borderRadius}}>
            <TouchableOpacity
              onPress={() => this.toggleArchive({productOrder})}
              dataSet={{fadeOnHover: 1}}
            >
              <Text style={{color: '#FFF'}}>{productOrder.archived ? 'Unarchive Order' : 'Archive Order'}</Text>
            </TouchableOpacity>
          </View>
        )}
      </View>
    );
  }
}

class OrdersPage extends PureComponent {
  state = {
    isHoveringRow: false,
    activePageNumber: 1,
    isLoading: true,
    productOrderCountsByStatusKey: {},
    isExporting: false
  };

  productOrdersContainerViewRef = createRef();

  async componentDidMount() {
    this.handleGetProductOrders();

    var {session: {activeOrg}} = this.props;
    var {isEmployee} = getUserEmployeeOrGuest({activeOrg});

    if (activeOrg.id === 850 && isEmployee) { //HINT MNA orders awaiting employee action
      this.getProductOrderCountsByStatusKey();
    }
  }

  componentDidUpdate(prevProps) {
    var {searchTerm, detailLevelFilterValue, orderOwnerFilterValue, archivedFilterValue, statusFilterValue, productCategoryFilterValue} = this.props;

    //HINT search and filter changes get product order data
    if (_.some([
      prevProps.searchTerm !== searchTerm,
      prevProps.orderOwnerFilterValue !== orderOwnerFilterValue,
      prevProps.archivedFilterValue !== archivedFilterValue,
      prevProps.statusFilterValue !== statusFilterValue,
      prevProps.productCategoryFilterValue !== productCategoryFilterValue,
      prevProps.detailLevelFilterValue !== detailLevelFilterValue
    ])) this.handleGetProductOrders();
  }

  getDataRequestFilters() {
    var filters = {};
    var {orderOwnerFilterValue, archivedFilterValue, statusFilterValue, productCategoryFilterValue} = this.props;
    var userId = this.props.session.user.id;

    if (orderOwnerFilterValue && orderOwnerFilterValue !== 'all') filters.orderOwner = userId;

    if (archivedFilterValue && archivedFilterValue !== 'all') {
      if (archivedFilterValue === 'active') filters.archived = 0;
      if (archivedFilterValue === 'archived') filters.archived = 1;
    }

    if (productCategoryFilterValue && productCategoryFilterValue !== 'all') filters.productCategoryId = parseInt(productCategoryFilterValue);

    if (statusFilterValue && statusFilterValue !== 'All') filters.statusKey = statusFilterValue;

    return filters;
  }

  handleGetProductOrders = async () => {
    this.setState({isLoading: true});

    var userId = this.props.session.user.id;

    var resultConstraints = {
      from: ((this.state.activePageNumber - 1) * this.props.pageRowsFilterValue),
      to: ((this.state.activePageNumber - 1) * this.props.pageRowsFilterValue) + eval(this.props.pageRowsFilterValue)
    };

    var getOrdersDataResponse = await sfApiNextRequest({path: '/api/configurator/get-configurator-orders-data', body: {
      userId,
      orgId: await sessionStore.get('activeOrgId'),
      includeAdvancedOrderData: this.props.detailLevelFilterValue === 'advanced',
      resultConstraints,
      searchTerm: this.props.searchTerm,
      filters: this.getDataRequestFilters()
    }});

    await this.props.trackProducts({products: _.get(getOrdersDataResponse, 'data.products'), reset: true});
    await this.props.trackProductOrders({productOrders: _.get(getOrdersDataResponse, 'data.productOrders'), reset: true});
    await this.props.trackProductCategories({productCategories: _.get(getOrdersDataResponse, 'data.productCategories'), reset: true});
    await this.props.trackProductOptions({productOptions: _.get(getOrdersDataResponse, 'data.productOptions'), reset: true});

    setTimeout(() => this.setState({isLoading: false}));
  };

  getProductOrderCountsByStatusKey = async () => {
    var {session: {activeOrg, user}} = this.props;
    var productOrderStatuses = _.get(activeOrg, 'appData.designEngine.productOrderStatuses');
    var statusKeys = _.map(productOrderStatuses, 'key');

    var {data: {productOrderCountsByStatusKey}} = await sfApiNextRequest({path: '/api/configurator/get-configurator-orders-count', body: {
      userId: user.id,
      orgId: activeOrg.id,
      statusKeys
    }});

    setTimeout(() => this.setState({productOrderCountsByStatusKey}));
  };

  handleFilterChange = ({key, value, updates}) => {
    if (updates) {
      var {ordersPageSettings} = this.props;

      ordersPageSettings = {...ordersPageSettings, ...updates};

      this.props.setAppData({key: 'ordersPageSettings', value: ordersPageSettings, appKey: 'designEngine'});
    }
    else {
      this.props.setAppData({key: `ordersPageSettings.${key}`, value, appKey: 'designEngine'});
    }

    this.setState({activePageNumber: 1});
  };

  handleExportCurrentListButtonPress = async () => {
    this.setState({isExporting: true});

    //HINT create headers, exclude empty header over the last column
    var csv = [_.compact(_.map(this.columnHeaderProps, 'title'))];

    try {
      var {data: {productOrdersCsvData}} = await sfApiNextRequest({path: '/api/configurator/get-configurator-orders-data', body: {
        userId: this.props.session.user.id,
        orgId: await sessionStore.get('activeOrgId'),
        includeAdvancedOrderData: this.props.detailLevelFilterValue === 'advanced',
        formatForCsvExport: true,
        filters: this.getDataRequestFilters()
      }});

      _.forEach(productOrdersCsvData, (productOrderRow) => csv.push(productOrderRow));

      var filename = `${getNameFor({orgId: this.props.session.activeOrgId, textToTransform: 'orders'})}-export-${moment().format('YYYY-MM-DD')}`;

      downloadCsv({csv, filename});
    }
    catch (error) {
      alert('Something went wrong while exporting the list to csv.');
      console.error(error);
    }

    this.setState({isExporting: false});
  };

  get defaultStatusFilterValue() {
    var {props} = this;
    var {session: {activeOrg: {role}}} = props;

    var productOrderStatuses = getFilteredProductOrderStatuses({productOrderStatuses: _.get(props.session, 'activeOrg.appData.designEngine.productOrderStatuses', []), userRole: role});

    if (_.size(productOrderStatuses)) {
      return _.get(_.first(productOrderStatuses), 'key');
    }
    else {
      return null;
    }
  }

  get columnHeaderProps() {
    var {session: {activeOrg}, detailLevelFilterValue} = this.props;
    var {isGuest, isGuestMode} = getUserEmployeeOrGuest({activeOrg});

    return [
      {title: activeOrg.id === 1798 ? 'Project' : 'Title', style: {minWidth: 300, flex: 1}},
      ...(activeOrg.id === 1798 ? [] : [
        {
          title: getNameFor({orgId: activeOrg.id, textToTransform: 'Order'}),
          style: {width: 75}
        },
      ]),
      {title: 'Product', style: {width: 150}},
      {title: 'Category', style: {width: 150}},
      {title: 'Owner', style: {width: 150}},
      ...((activeOrg.id === 850 && (detailLevelFilterValue === 'advanced')) ? [
        {title: 'Company Name', style: {width: 150}},
      ] : []),
      ...(activeOrg.id === 1798 ? [
        {title: 'Account / Client', style: {width: 150}},
        {title: 'Invoice #', style: {width: 100}},
        {title: 'Salesforce Link', style: {width: 150}}
      ] : []),
      {title: 'Status', style: {width: 150}},
      ...((activeOrg.id === 850 && (detailLevelFilterValue === 'advanced')) ? [
        {title: 'Created', align: 'right', style: {width: 100}},
        {title: 'Updated', align: 'right', style: {width: 100}},
        {title: 'Subtotal', align: 'right', style: {width: 100}},
        {title: 'Discount', align: 'right', style: {width: 100}},
        {title: 'Shipping', align: 'right', style: {width: 100}},
        {title: 'Tax', align: 'right', style: {width: 100}}
      ] : []),
      {
        title: getNameFor({orgId: activeOrg.id, textToTransform: 'Order Total'}),
        align: 'right',
        style: {width: 125}
      },
      {title: 'Amount Paid', align: 'right', style: {width: 125}},
      ...(activeOrg.id === 850 && (detailLevelFilterValue === 'advanced' && (!isGuest || !isGuestMode)) ? [
        {title: 'Last Payment', align: 'right', style: {width: 100}},
        {title: 'Items', align: 'center', style: {width: 50}},
        {title: 'Screw Type', style: {width: 100}},
        {title: 'Engraving Type(s)', style: {width: 150}},
        {title: 'Plate Finish(es)', style: {width: 250}},
        {title: 'Mechanism Finish(es)', style: {width: 200}},
        {title: 'Estimated Ship', align: 'right', style: {width: 125}},
        {title: 'Command #', align: 'right', style: {width: 125}},
        {title: 'Command Date', align: 'right', style: {width: 125}},
        {title: 'Command Total', align: 'right', style: {width: 125}},
      ] : []),
      {title: '', style: {width: 25}}
    ];
  }

  handleUpdatePageRows = ({value}) => {
    this.handleFilterChange({key: 'pageRowsFilterValue', value});

    this.setState({activePageNumber: 1}, () => this.handleGetProductOrders());
  };

  handleUpdateActivePageNumber = ({increment, decrement}) => {
    if (increment) {
      this.setState({activePageNumber: this.state.activePageNumber + 1}, () => this.handleGetProductOrders());
    }

    if (decrement && !(this.state.activePageNumber === 1)) {
      this.setState({activePageNumber: this.state.activePageNumber - 1}, () => this.handleGetProductOrders());
    }

    this.productOrdersContainerViewRef.current.scrollTo({top: 0});
  };

  render() {
    var {productOrders, session, updateProductOrder, orderOwnerFilterValue, archivedFilterValue, statusFilterValue, pageRowsFilterValue, productCategoryFilterValue, productCategoriesById, detailLevelFilterValue} = this.props;
    var {isLoading, activePageNumber, productOrderCountsByStatusKey, isExporting} = this.state;
    var {activeOrg, user} = session;
    var {appData, role} = activeOrg;
    var {isEmployee, isGuestMode} = getUserEmployeeOrGuest({activeOrg});

    var productOrderStatuses = getFilteredProductOrderStatuses({productOrderStatuses: _.get(appData, 'designEngine.productOrderStatuses', []), userRole: role});
    var productOrderStatusesByKey = _.mapKeys(productOrderStatuses, (item) => item.key);
    var statusFilterOptions = _.map([{title: 'All', type: 'all'}, ...productOrderStatuses], (status) => ({value: status.key, title: status.title}));
    var productCategoriesFilterOptions = _.map([{title: 'All', id: 'all'}, ..._.map(productCategoriesById)], (productCategory) => ({value: productCategory.id, title: productCategory.title}));

    statusFilterValue = statusFilterValue || this.defaultStatusFilterValue;

    var orderOwnerFilterOptions = [
      {value: 'all', title: 'Anyone'},
      {value: 'self', title: getNameFor({orgId: this.props.session.activeOrg.id, textToTransform: 'My Orders'})}
    ];

    var detailLevelFilterOptions = [
      {value: 'basic', title: 'Basic'},
      {value: 'advanced', title: 'Advanced'}
    ];

    var pageRowsFilterOptions = [
      {value: 50, title: '50'},
      {value: 100, title: '100'},
      {value: 150, title: '150'},
      {value: 200, title: '200'},
      {value: 250, title: '250'}
    ];

    var {isGuest, isGuestMode} = getUserEmployeeOrGuest({activeOrg});

    if (isGuestMode) {
      productOrders = _.filter(productOrders, {userId: user.id});
    }

    productOrders = _.orderBy(productOrders, 'lastUpdated', 'desc');

    var filterInputStyles = {
      style: {height: K.inputHeight},
      buttonStyle: {backgroundColor: K.colors.doubleGray, maxWidth: 150}
    };

    var requiresAttentionStyles = {
      style: {
        outerView: {marginRight: K.spacing}
      },
      buttonStyle: {
        backgroundColor: K.colors.doubleGray,
        height: K.inputHeight,
        width: 50
      }
    };

    var isLastPage = _.slice([...productOrders], pageRowsFilterValue * (activePageNumber - 1), pageRowsFilterValue * activePageNumber).length < pageRowsFilterValue;

    var numberOfOrdersOnPage = _.slice([...productOrders], pageRowsFilterValue * (activePageNumber - 1), pageRowsFilterValue * activePageNumber).length;

    if (isLoading) return (<Loading />);
    else {
      return (
        <DocumentTitle title={getNameFor({orgId: this.props.session.activeOrg.id, textToTransform: 'My Orders'})}>
          <View style={{flex: 1, position: 'relative'}}>
            <SecondaryHeader
              title={getNameFor({orgId: this.props.session.activeOrg.id, textToTransform: 'Orders'})}
              rightComponent={(
                <View style={{flexDirection: 'row', alignItems: 'flex-end', justifyContent: 'flex-end'}}>
                  {(activeOrg.id === 850 && (isEmployee && !isGuestMode) && !_.isEmpty(productOrderCountsByStatusKey)) && (
                    <View style={{flexDirection: 'row', justifyContent: 'flex-end'}}>
                      {_.map([
                        {statusKey: 'submittedForApproval', abbreviation: 'SUB'},
                        {statusKey: 'readyToPayDeposit', abbreviation: 'CDR'},
                        {statusKey: 'finalPaymentReceived', abbreviation: 'FPR'},
                      ], ({statusKey, abbreviation}) => {
                        return (
                          <Tooltip text={_.get(productOrderStatusesByKey, statusKey).title}>
                            <LabelledView
                              label={abbreviation}
                              styles={{...requiresAttentionStyles.style}}
                            >
                              <Button
                                style={{...requiresAttentionStyles.buttonStyle}}
                                onPress={() => {
                                  this.handleFilterChange({updates: {
                                    orderOwnerFilterValue: 'all',
                                    archivedFilterValue: 'active',
                                    productCategoryFilterValue: 'all',
                                    statusFilterValue: statusKey
                                  }});
                                }}
                              >
                                <Text>{_.get(productOrderCountsByStatusKey, statusKey, 0)}</Text>
                              </Button>
                            </LabelledView>
                          </Tooltip>
                        );
                      })}
                    </View>
                  )}
                  <LabelledView label={'Owner'} styles={{outerView: {marginRight: K.spacing}}}>
                    <PickerInput
                      key={orderOwnerFilterValue}
                      style={{...filterInputStyles.style}}
                      buttonStyle={{...filterInputStyles.buttonStyle}}
                      showDownArrow={true}
                      options={orderOwnerFilterOptions}
                      value={orderOwnerFilterValue}
                      onChange={({value}) => this.handleFilterChange({key: 'orderOwnerFilterValue', value})}
                    />
                  </LabelledView>
                  <LabelledView
                    label={'Type'}
                    styles={{outerView: {marginRight: K.spacing}}}
                  >
                    <PickerInput
                      key={archivedFilterValue}
                      style={{...filterInputStyles.style}}
                      buttonStyle={{...filterInputStyles.buttonStyle}}
                      showDownArrow={true}
                      options={[
                        {value: 'active', title: getNameFor({orgId: this.props.session.activeOrg.id, textToTransform: 'Active Orders'})},
                        {value: 'archived', title: getNameFor({orgId: this.props.session.activeOrg.id, textToTransform: 'Archived Orders'})},
                        {value: 'all', title: getNameFor({orgId: this.props.session.activeOrg.id, textToTransform: 'All Orders'})},
                      ]}
                      value={archivedFilterValue}
                      onChange={({value}) => this.handleFilterChange({key: 'archivedFilterValue', value})}
                    />
                  </LabelledView>
                  <LabelledView
                    label={'Category'}
                    styles={{outerView: {marginRight: K.spacing}}}
                  >
                    <PickerInput
                      key={productCategoryFilterValue}
                      style={{...filterInputStyles.style}}
                      buttonStyle={{...filterInputStyles.buttonStyle}}
                      showDownArrow={true}
                      options={productCategoriesFilterOptions}
                      value={productCategoryFilterValue}
                      onChange={({value}) => this.handleFilterChange({key: 'productCategoryFilterValue', value})}
                    />
                  </LabelledView>
                  <LabelledView label='Status' styles={{outerView: {marginRight: K.spacing}}}>
                    <PickerInput
                      key={statusFilterValue}
                      style={{...filterInputStyles.style}}
                      buttonStyle={{...filterInputStyles.buttonStyle}}
                      showDownArrow={true}
                      options={statusFilterOptions}
                      value={statusFilterValue}
                      onChange={({value}) => this.handleFilterChange({key: 'statusFilterValue', value})}
                    />
                  </LabelledView>
                  {(isEmployee && !isGuestMode) && (
                    <LabelledView label='View' styles={{outerView: {marginRight: K.spacing}}}>
                      <PickerInput
                        style={{...filterInputStyles.style}}
                        buttonStyle={{...filterInputStyles.buttonStyle}}
                        showDownArrow={true}
                        options={detailLevelFilterOptions}
                        value={detailLevelFilterValue}
                        onChange={({value}) => this.handleFilterChange({key: 'detailLevelFilterValue', value})}
                      />
                    </LabelledView>
                  )}
                  <LabelledView label={'Items/Page'}>
                    <PickerInput
                      value={pageRowsFilterValue}
                      options={pageRowsFilterOptions}
                      onChange={this.handleUpdatePageRows}
                      style={{...filterInputStyles.style}}
                      buttonStyle={{...filterInputStyles.buttonStyle}}
                      showDownArrow={true}
                    />
                    <div style={{position: 'absolute', top: K.spacing * 3.5}}>
                      <div style={{display: 'flex', flexDirection: 'row', alignItems: 'center', gap: K.spacing}}>
                        <Button
                          onPress={() => {
                            if (activePageNumber !== 1) {
                              this.handleUpdateActivePageNumber({decrement: true});
                            }
                          }}
                          disabled={activePageNumber === 1}
                          icon={leftArrowIcon}
                          style={{backgroundColor: 'transparent', ...K.defaultIconSize * 2, alignItems: 'center',
                            opacity: activePageNumber === 1 ? 0.2 : 1
                          }}
                        />
                        <Button
                          onPress={() => {
                            if (!isLastPage) {
                              this.handleUpdateActivePageNumber({increment: true});
                            }}}
                          icon={leftArrowIcon}
                          style={{backgroundColor: 'transparent', ...K.defaultIconSize * 2, transform: 'rotate(180deg)', alignItems: 'center',
                            opacity: isLastPage ? 0.2 : 1
                          }}
                        />
                      </div>
                    </div>
                  </LabelledView>
                </View>
              )}
              belowHeaderComponent={(
                <>
                  {activeOrg.id === 850 && (
                    <View style={{marginTop: K.spacing * 2, flexDirection: 'column', flex: 1, justifyContent: 'flex-end'}}>
                      <View style={{flexDirection: 'row'}}>
                        <Tooltip left text='Export Current List to CSV'>
                          {!isExporting ? (
                            <Button
                              style={{backgroundColor: K.color, margin: K.margin, ...K.defaultIconSize}}
                              onPress={() => this.handleExportCurrentListButtonPress()}
                              icon={downloadIcon}
                            />
                          ) : (
                            <View style={{margin: K.margin, ...K.defaultIconSize}}>
                              <ActivityIndicator size='small' color='black'/>
                            </View>
                          )}
                        </Tooltip>
                        {/* {_.includes([1, 2], user.id) && ( //HINT only visible to Max & Chris
                          <Tooltip left text='update costs for 50 product orders with effective pricing date 2024-07-19 00:00:00'>
                            <Button
                              style={{backgroundColor: K.color, margin: K.margin, ...K.defaultIconSize}}
                              onPress={async () => await api.request({uri: '/configurator/update-product-order-costs'})}
                              icon={downloadIcon}
                            />
                          </Tooltip>
                        )} */}
                      </View>
                    </View>
                  )}
                </>
              )}
            ></SecondaryHeader>
            <ScrollView horizontal={true} style={{flex: 1}} contentContainerStyle={{minWidth: '100%'}}>
              <ScrollView style={{flex: 1}} contentContainerStyle={{paddingTop: K.spacing, paddingBottom: 200}} ref={this.productOrdersContainerViewRef}>
                <View style={{marginHorizontal: K.spacing, justifyContent: 'space-evenly', paddingVertical: K.spacing, paddingLeft: K.spacing, flexDirection: 'row', alignItems: 'flex-end'}}>
                  {_.map(this.columnHeaderProps, ({title, style, align}, index) => (
                    <View key={index}
                      style={{
                        ...style,
                        ...(align === 'right' && {alignItems: 'flex-end', textAlign: 'right'}),
                        ...(align === 'center' && {alignItems: 'center', textAlign: 'center'}),
                        ...(index + 1 === this.columnHeaderProps.length ? {marginRight: 0} : {marginRight: K.spacing})
                      }}
                    >
                      <Label>{title}</Label>
                    </View>
                  ))}
                </View>
                {(numberOfOrdersOnPage < 1 && !isLoading) ? (
                  <Text style={{...K.fonts.label, marginLeft: K.spacing * 2, marginTop: K.spacing * 2}}>
                    {`No ${getNameFor({orgId: this.props.session.activeOrgId, textToTransform: 'orders'})} found`}
                  </Text>
                ) : (
                  <View style={{marginBottom: K.spacing * 5}}>
                    {_.map(
                      _.slice([...productOrders], pageRowsFilterValue * (activePageNumber - 1), pageRowsFilterValue * activePageNumber), (productOrder) => (
                        <ProductOrderRow
                          key={productOrder.id}
                          {...{productOrder, productOrderStatuses, productOrderStatusesByKey, activeOrgId: activeOrg.id, isGuest, isGuestMode, updateProductOrder, productCategoriesById, detailLevelFilterValue}}
                        />
                      ))}
                  </View>
                )}
              </ScrollView>
            </ScrollView>
          </View>
        </DocumentTitle>
      );
    }
  }
}

export default connect({
  mapState: (state) => {
    var orderOwnerFilterValue = _.get(state, 'session.user.appData.designEngine.ordersPageSettings.orderOwnerFilterValue', 'all');
    var archivedFilterValue = _.get(state, 'session.user.appData.designEngine.ordersPageSettings.archivedFilterValue', 'active');
    var statusFilterValue = _.get(state, 'session.user.appData.designEngine.ordersPageSettings.statusFilterValue', 'All');
    var pageRowsFilterValue = _.get(state, 'session.user.appData.designEngine.ordersPageSettings.pageRowsFilterValue', 100);
    var productCategoryFilterValue = _.get(state, 'session.user.appData.designEngine.ordersPageSettings.productCategoryFilterValue', 'all');
    var detailLevelFilterValue = _.get(state, 'session.user.appData.designEngine.ordersPageSettings.detailLevelFilterValue', 'basic');

    return {
      productOrders: state.resources.productOrders.byId,
      productsById: state.resources.products.byId,
      productOptionsById: state.resources.productOptions.byId,
      productCategoriesById: state.resources.productCategories.byId,
      searchTerm: state.activeView.data.searchTerm,
      orderOwnerFilterValue, archivedFilterValue, statusFilterValue, pageRowsFilterValue, productCategoryFilterValue,
      detailLevelFilterValue,
      orderPageSettings: _.get(state, 'session.user.appData.designEngine.ordersPageSettings', {})
    };
  },
  mapDispatch: {
    ..._.pick(resourceActions.products, ['trackProducts']),
    ..._.pick(resourceActions.productOrders, ['trackProductOrders', 'updateProductOrder']),
    ..._.pick(resourceActions.productCategories, ['trackProductCategories']),
    ..._.pick(resourceActions.productOptions, ['trackProductOptions']),
    setAppData
  }
})(OrdersPage);
