import _ from 'lodash';
import { products } from '@symbolic/lib';
import { sortedProductPropertiesForProductInstance } from '~/helpers/product-order-helper';

var { defaultProductOptionFor } = products;

var selectedOptionFor = ({productInstance, productPropertyId}, dependencies) => {
  var {productOptionsById} = dependencies;

  var defaultOption = defaultProductOptionFor({productPropertyId}, {productOptionsById});
  var selectedOptionId = _.get(productInstance, `properties.${productPropertyId}.optionId`, defaultOption.id);

  return selectedOptionId;
};

var decoraReferenceCodeFor = ({productInstance}, dependencies) => {
  var referenceCode = '';

  var cutoutTypeOptionId = selectedOptionFor({productInstance, productPropertyId: 87}, dependencies);
  var screwTypeOptionId = selectedOptionFor({productInstance, productPropertyId: 3}, dependencies);

  if (screwTypeOptionId === 103) { //HINT hidden screw plate sizes
    var plateSizeOptionId = selectedOptionFor({productInstance, productPropertyId: 115}, dependencies);

    if (cutoutTypeOptionId === 526 && plateSizeOptionId === 706) {
      referenceCode = 'F24-1335-V1-A'; //NEW ARCHITECTURAL - HIDDEN SCREWS
    }
    else if (cutoutTypeOptionId === 525 && plateSizeOptionId === 706) {
      referenceCode = 'F21-1731-V1-A'; //DECORATOR - HIDDEN SCREWS
    }
    else if (cutoutTypeOptionId === 525 && plateSizeOptionId === 707) {
      referenceCode = 'F22-1580-V1-A'; //DECORATOR - HIDDEN SCREWS - 2-GANG
    }
    else if (cutoutTypeOptionId === 525 && plateSizeOptionId === 708) {
      referenceCode = 'F22-1775-V1-A '; //DECORATOR - HIDDEN SCREWS - 3-GANG
    }
    else if (cutoutTypeOptionId === 525 && plateSizeOptionId === 709) {
      referenceCode = 'F22-1776-V1-A'; //DECORATOR - HIDDEN SCREWS - 4-GANG
    }
    else if (cutoutTypeOptionId === 525 && plateSizeOptionId === 710) {
      referenceCode = '*tbd'; //DECORATOR - HIDDEN SCREWS - 5-GANG
    }
  }
  else { //HINT visible screw plate sizes
    var plateSizeOptionId = selectedOptionFor({productInstance, productPropertyId: 86}, dependencies);

    if (cutoutTypeOptionId === 526 && plateSizeOptionId === 520) {
      referenceCode = 'F22-0422-V1-A'; //NEW ARCHITECTURAL - VISIBLE SCREWS
    }
    else if (cutoutTypeOptionId === 525 && plateSizeOptionId === 520) {
      referenceCode = 'F20-0421-V1-A'; //DECORATOR - VISIBLE SCREWS
    }
    else if (cutoutTypeOptionId === 525 && plateSizeOptionId === 521) {
      referenceCode = 'F20-1355-V1-A'; //DECORATOR - VISIBLE SCREWS - 2-GANG
    }
    else if (cutoutTypeOptionId === 525 && plateSizeOptionId === 522) {
      referenceCode = 'F20-1356-V1-A'; //DECORATOR - VISIBLE SCREWS - 3-GANG
    }
    else if (cutoutTypeOptionId === 525 && plateSizeOptionId === 523) {
      referenceCode = 'F20-1357-V1-A'; //DECORATOR - VISIBLE SCREWS - 4-GANG
    }
    else if (cutoutTypeOptionId === 525 && plateSizeOptionId === 524) {
      referenceCode = 'F21-1915-V1'; //DECORATOR - VISIBLE SCREWS - 5-GANG
    }
  }

  return referenceCode;
};

const referenceCodeFor = ({productInstance, type, activeOrgId}, dependencies) => {
  var {product, productsById, productPropertiesById, productRulesById, productOptionsById, productInstancesById} = dependencies;

  var referenceCode = '';

  var isDecoraProduct = product.id === 98;
  var productHasReferenceCodeDataForType = product.referenceCodes[type];

  if (isDecoraProduct) {
    referenceCode = decoraReferenceCodeFor({productInstance}, {productOptionsById});
  }
  else if (productHasReferenceCodeDataForType) {
    if (type === 'factory' && activeOrgId === 850) referenceCode = 'F-';

    referenceCode = `${referenceCode}${product.referenceCodes[type]}`;

    var productHasReferenceCodeFormulaForType = product.referenceCodeFormulas[type];

    if (productHasReferenceCodeFormulaForType) {
      if (_.get(product, 'associations.productProperties')) {
        var {productPropertySequence, separator} = product.referenceCodeFormulas[type];

        var productPropertiesForProductInstance = sortedProductPropertiesForProductInstance({productInstance}, {productsById, productPropertiesById, productRulesById, productOptionsById, productInstancesById});

        _.forEach(productPropertySequence, productPropertyId => {
          //HINT check if productProperty is valid for productInstance based on rules
          var productPropertyIsValid = _.filter(productPropertiesForProductInstance, {id: productPropertyId}).length > 0;

          if (productPropertyIsValid) {
            var defaultOption = defaultProductOptionFor({productPropertyId}, {productOptionsById});
            var selectedOptionId = _.get(productInstance, `propertiesDataById.${productPropertyId}.selectedProductOption.id`, defaultOption.id);

            var productOption = productOptionsById[selectedOptionId];

            //MNA specific
            //HINT special combined condition for screw type/edge type
            if (type === 'factory' && productPropertyId === 3 && activeOrgId === 850) {
              var slug;

              var selectedEdgeType = () => {
                var defaultOption = defaultProductOptionFor({productPropertyId: 2}, {productOptionsById});
                var selectedOptionId = _.get(productInstance, `properties.${2}.optionId`, defaultOption.id);

                return selectedOptionId;
              };

              if (_.includes([5, 6], selectedOptionId)) { //screw type visible
                if (selectedEdgeType() === 3) slug = 'A'; //edge type beveled
                else if (selectedEdgeType() === 4) slug = 'B'; //edge type straight
              }
              else if (selectedOptionId === 103) { //screw type hidden
                if (selectedEdgeType() === 3) slug = 'C'; //edge type beveled
                else if (selectedEdgeType() === 4) slug = 'D'; //edge type straight
              }

              referenceCode = `${referenceCode}${separator}${slug}`;
            }
            else {
              referenceCode = `${referenceCode}${separator}${_.get(productOption, `referenceCodes[${type}]`, '*')}`;
            }
          }
        });
      }
    }
  }

  return referenceCode;
};

export { referenceCodeFor };
