import { store } from './src/redux';
import { Platform } from 'react-native';
import { Provider } from 'react-redux';
import { useFonts } from 'expo-font';

import App from './src/app';
import React from 'react';
import './global.js';
import style from './src/helpers/document-style-tag';
import K from '~/k';

if (__DEV__ && Platform.OS !== 'web') {
  var { LogBox } = require('react-native');

  LogBox.ignoreAllLogs(true);
}

//test

if (__DEV__) {
  process.env.REACT_APP_LOCAL_IP = '192.168.86.112'; //uncomment for api access from local phone
}

export default function Configurator() {
  useFonts({
    'Berlin-Sans': require('~/assets/meljac/fonts/Berlin-Sans.ttf'),
    'Helvetica': require('~/assets/meljac/fonts/Helvetica-Light.ttf'),
    'Futura': require('~/assets/meljac/fonts/Futura.ttf'),
    'Akzidenz-Grotesk': require('~/assets/meljac/fonts/Akzidenz-Grotesk.ttf'),
    'Twentieth-Century-MT': require('~/assets/meljac/fonts/Twentieth-Century-MT.ttf'),
    'Baskerville-Regular': require('~/assets/meljac/fonts/Baskerville-Regular.ttf'),
    'Neutraface-Text-Book': require('~/assets/meljac/fonts/Neutraface-Text-Book.ttf'),
    'Voyager': require('~/assets/meljac/fonts/Voyager.ttf'),
    'Courier': require('~/assets/meljac/fonts/Courier.ttf'),
    'Garamond': require('~/assets/meljac/fonts/Garamond.ttf'),
    'Cambria': require('~/assets/meljac/fonts/Cambria.ttf'),
    'Brandon': require('~/assets/meljac/fonts/Brandon.ttf'),
    'Book-Light': require('~/assets/meljac/fonts/Book-Light.ttf'),
    'Century-Gothic': require('~/assets/meljac/fonts/Century-Gothic.ttf'),
    'Truly-Offset': require('~/assets/meljac/fonts/Truly-Offset.ttf'),
  });

  return (
    <Provider store={store}>
      <App />
    </Provider>
  );
}

//HINT inject web css
if (Platform.OS === 'web') {
  const webCss = `
* {
  appearance: none;
  -webkit-appearance: none;
  outline: none !important;
  box-sizing: border-box;
}
select {
  border-width: 0px;
  font-size: 11.9px;
  letter-spacing: 0.5px;
  min-width: 0px !important;
}
img {
  image-rendering: -webkit-optimize-contrast;
  z-index: initial !important;
}
#root > div {
  border-width: 0px !important;
}
div::-webkit-scrollbar {
  background-color: #eee;
}
div::-webkit-scrollbar-track {
  box-shadow: none;
  border: none;
}
div::-webkit-scrollbar-thumb {
  background-color: #ccc;
}
div[data-word-break-all] {
  -ms-word-break: break-all;
  word-break: break-all;

  -webkit-hyphens: auto;
  -moz-hyphens: auto;
  hyphens: auto;
}

/* HINT constrain app inside window */
html, body, #root {
  height: 100%;
}
div[data-transition-opacity] {
  transition: opacity 0.5s;
}
div[data-intro-slider] {
  overflow-x: hidden !important;
  transition: opacity 1s;
}
#root > div > div > div > div {
  flex-shrink: initial;
  overflow: hidden;
  height: 100%;
}
#root > div > div > div > div > div {
  height: 100%;
}
div[data-intro-slider] > div > div > div {
  flex-grow: 1;
}
/* HINT fix dash */
.css-view-1dbjc4n, .css-1dbjc4n {
  z-index: initial;
}
div[data-hover]:hover {
  opacity: 0.7 !important;
}
div[data-cursor-pointer] {
  cursor: pointer !important;
}
div[data-grab]:hover {
  cursor: grab;
}
div[data-grabbing] {
  cursor: grabbing;
}
div[data-hover-outline]:hover {
  border: 1px solid black !important;
}
div[data-hover-outline-double-gray]:hover {
  border: 1px solid ${K.colors.doubleGray} !important;
}
div[data-hover-background-double-gray]:hover {
  background-color: ${K.colors.doubleGray} !important;
}
div[data-hover-white-space-normal]:hover {
  white-space: normal !important;
}
div[data-focusable="true"]:not([data-nohover]):not([role="button"]):hover, div[data-pressable="1"]:not([data-nohover]):hover {
  opacity: 0.85;
}
div[data-report="1"] {
  page-break-after: always;
  display: block;
}
div[data-popup="1"] {
  transform: translateZ(0);
}
div[data-hover-underline="1"]:hover {
  text-decoration: underline !important;
}
div[data-create-product-row]:hover div {
  opacity: 1 !important;
}
*[data-conditional-opacity="1"] {
  transition: opacity 0.5s;
}
div[data-conditional-opacity-parent="1"]:not(:hover) *[data-conditional-opacity="1"] {
  opacity: 0 !important;
  pointer-events: none;
}
*[data-background-only-when-focused]:not(:focus, :hover) {
  background-color: transparent !important;
}
div[data-border-black-on-hover="1"]:hover {
  border-color: black !important;
}
div[data-fade-on-hover="1"]:hover {
  opacity: 0.8;
}
div[data-grow-on-hover="1"] {
  transform: scale(1);
  transition: transform 100ms ease-in-out !important;
}
div[data-grow-on-hover="1"]:hover {
  transform: scale(1.1);
}

@font-face {
  font-family: DIN;
  src: url('https://symbolic-public.s3.amazonaws.com/fonts/DIN/DIN-Light.ttf');
  font-display: auto;
}
`;

  style.textContent = webCss;

  document.head.append(style);
}
